import { IconButton } from '@material-ui/core';
import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/Dialogs/ResponsiveDialog';
import { QueryState } from 'components/QueryState';
import {
  H4,
  H6,
  TextAlign as HTextAlign,
} from 'components/design-system/Heading/Heading';
import { StyledLink } from 'components/design-system/Link';
import {
  StepActions,
  StepButton,
  StepIntroductionTypography,
} from 'components/design-system/StepComponents/StepComponents';
import {
  TextAlign,
  TextLarge,
  TextSmall,
} from 'components/design-system/Text/Text';
import {
  Pill,
  PillContainer,
} from 'components/feature/PortfolioBuilder/AddToBasket/AddToBasketDialog.style';
import { colors } from 'constants/colors';
import { currencyFull, date, day } from 'formatting';
import { WrapperType } from 'generated/graphql';
import {
  getPathSegmentForWrapperType,
  getShortNameForWrapperType,
} from 'helpers/accountHelpers';
import { generateAutoSaveInvestSubPath } from 'paths';
import { useEffect, useState } from 'react';
import { FaUndo } from 'react-icons/fa';
import { HiOutlineTrash } from 'react-icons/hi';
import { MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  AccountsQueryAccount,
  UserProfileQueryClientSummaryAccount,
} from 'types/graphqlTypes';
import { useAutoSaveInvestState } from '../AutoSaveInvestContext';
import { RegularDepositEditForm } from './dialogs/RegularDepositEditForm';

export interface RegularDepositLineFormValues {
  amount: number;
  paymentDate: number;
  autoInvest: boolean;
  isDeleted: 'true' | 'false';
  isEdited: 'true' | 'false';
  isNew: 'true' | 'false';
}

export const Section = styled.div`
  padding: 1rem 0;
  margin: 1.5rem 0 0 0;
  border-top: 1px solid ${colors.midGrey};
  border-bottom: 1px solid ${colors.midGrey};
  & + & {
    margin-top: 0;
    border-top: none;
  }
`;

export const DepositContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2.5rem 2.5rem;
  align-items: center;
`;

const Amount = styled(TextLarge)`
  margin: 0.25rem 0;
`;

export interface IntroductionFormValue {
  selectedRegularDeposits: boolean[];
}

export interface MultipleDepositsStepProps {
  onProceed?: (newUpdatedDepositAmount: number) => void;
  wrapperType: WrapperType;
  onEdit: () => void;
}

export function MultipleDepositsStep({
  onProceed,
  wrapperType,
  onEdit,
}: MultipleDepositsStepProps) {
  const {
    state,
    setState,
    latestEmployerContribution,
  } = useAutoSaveInvestState();

  const recurringDeposits = state?.deposits ?? [];
  const deposit = recurringDeposits[0];

  const onDelete = async () => {
    const existingDeposit = state?.deposits?.[0]!;

    if (existingDeposit.isNew) {
      setState({
        ...state,
        deposits: [],
      });
    } else {
      const newSelectedRegularDeposit = {
        ...existingDeposit,
        isDeleted: !existingDeposit.isDeleted,
      };

      setState({
        ...state,
        deposits: [newSelectedRegularDeposit],
      });
    }
  };

  return (
    <div>
      <PillContainer>
        <Pill>{getShortNameForWrapperType(wrapperType)}</Pill>
      </PillContainer>
      <H4>Manage regular deposits</H4>

      {latestEmployerContribution && (
        <Section>
          <H6 $noMargin>Estimated employer contribution</H6>
          <Amount $noMargin>
            {currencyFull(latestEmployerContribution.amount)}
          </Amount>
          <TextSmall $noMargin>
            This is based on your latest employer contribution on the{' '}
            {date(latestEmployerContribution.completedDate)}
          </TextSmall>
        </Section>
      )}

      {recurringDeposits.length === 0 && !latestEmployerContribution && (
        <Section>
          <StepIntroductionTypography textAlign="left">
            You don't have any regular deposits setup for auto investment.
          </StepIntroductionTypography>
          <StepIntroductionTypography textAlign="left">
            You need to select the deposits you want to auto invest, or{' '}
            <StyledLink
              as={Link}
              to={{
                pathname: generateAutoSaveInvestSubPath({
                  wrapperType: getPathSegmentForWrapperType(wrapperType),
                  action: 'create-deposit',
                }),
                state: { from: 'auto-save-invest' },
              }}
            >
              setup a new regular deposit
            </StyledLink>
            .
          </StepIntroductionTypography>
        </Section>
      )}
      {recurringDeposits.length === 0 && latestEmployerContribution && (
        <Section>
          <H6 $noMargin $textAlign={HTextAlign.left}>
            Regular deposit
          </H6>
          <TextSmall $textAlign={TextAlign.left} $noMargin>
            In addition to your employer contribution, you can set up a standing
            order to invest a regular amount each month.
          </TextSmall>
          <TextSmall $textAlign={TextAlign.left} $noMargin>
            <StyledLink
              as={Link}
              to={{
                pathname: generateAutoSaveInvestSubPath({
                  wrapperType: getPathSegmentForWrapperType(wrapperType),
                  action: 'create-deposit',
                }),
                state: { from: 'auto-save-invest' },
              }}
            >
              Setup a new regular deposit
            </StyledLink>
            .
          </TextSmall>
        </Section>
      )}

      {recurringDeposits.length > 0 && (
        <Section>
          <H6 $noMargin $textAlign={HTextAlign.left}>
            Regular order
          </H6>
          <DepositContainer key={deposit.paymentDate}>
            <Amount $noMargin>
              {currencyFull(deposit.amount!)} on the {day(deposit.paymentDate!)}{' '}
              of the month
              {deposit.autoInvest ? ' auto invest' : 'hold as cash'}
              {deposit.isDeleted === true && ' (removed)'}
              {deposit.isNew === true && ' (new)'}
              {deposit.isEdited === true &&
                deposit.isNew !== true &&
                deposit.isDeleted !== true &&
                ' (updated)'}
            </Amount>

            <IconButton
              type="button"
              size="small"
              onClick={() => {
                onEdit?.();
              }}
            >
              <MdEdit title="Edit" />
            </IconButton>

            {deposit.isDeleted ? (
              <IconButton
                type="button"
                size="small"
                onClick={() => {
                  onDelete();
                }}
              >
                <FaUndo title="Undo" />
              </IconButton>
            ) : (
              <IconButton
                type="button"
                size="small"
                onClick={() => {
                  onDelete();
                }}
              >
                <HiOutlineTrash title="Remove" />
              </IconButton>
            )}
          </DepositContainer>
          {deposit.isDeleted && (
            <TextSmall $noMargin>
              You'll need to cancel your standing order for this deposit.
            </TextSmall>
          )}
          {deposit.isNew && (
            <TextSmall $noMargin>
              You'll need to setup a standing order for this deposit.
            </TextSmall>
          )}
          {!deposit.isNew && !deposit.isDeleted && (
            <TextSmall $noMargin>
              You should have a standing order for this deposit.
            </TextSmall>
          )}
        </Section>
      )}
      <StepActions>
        <StepButton
          onClick={() => {
            onProceed?.(deposit.amount ?? 0);
          }}
          className="magenta"
        >
          Close
        </StepButton>
      </StepActions>
    </div>
  );
}

interface RegularDepositEditProps {
  onClose: () => void;
  account: AccountsQueryAccount | UserProfileQueryClientSummaryAccount;
}

export const RegularDepositEdit = ({
  onClose,
  account,
}: RegularDepositEditProps) => {
  const { state, setState } = useAutoSaveInvestState();

  if (state?.deposits === null || state?.deposits === undefined) {
    return null;
  }

  const selectedDeposit = state?.deposits[0];
  return (
    <RegularDepositEditForm
      onComplete={() => {
        onClose();
      }}
      deposit={selectedDeposit}
      index={0}
      account={account}
      onDepositChange={(updatedDeposit) => {
        if (state?.deposits === null || state?.deposits === undefined) {
          return;
        }

        setState({
          ...state,
          deposits: [{ ...selectedDeposit, ...updatedDeposit, isEdited: true }],
        });

        onClose();
      }}
      variant="autoSaveInvest"
    />
  );
};

interface MultipleDepositsStepDialogProps extends ResponsiveDialogProps {
  onProceed: (newUpdatedDepositAmount: number) => void;
  open: boolean;
  closeDialog: () => void;
  account: AccountsQueryAccount | UserProfileQueryClientSummaryAccount;
}

export function MultipleDepositsStepDialog({
  onProceed,
  open,
  closeDialog,
  account,
}: MultipleDepositsStepDialogProps) {
  const {
    state,
    latestEmployerContribution,
    recurringTransactionsQuery,
  } = useAutoSaveInvestState();

  const [activeState, setActiveState] = useState<'info' | 'edit' | undefined>(
    undefined
  );

  const hasSingleDeposit = state?.deposits?.length === 1;
  const onlyShowEdit = hasSingleDeposit && !latestEmployerContribution;

  useEffect(() => {
    if (activeState === undefined && recurringTransactionsQuery.isSuccess) {
      setActiveState(onlyShowEdit ? 'edit' : 'info');
    }
  }, [activeState, recurringTransactionsQuery.isSuccess, onlyShowEdit]);

  return (
    <ResponsiveDialog open={open} onClose={closeDialog}>
      <QueryState {...recurringTransactionsQuery}>
        {() => (
          <>
            {open && activeState === 'info' && (
              <MultipleDepositsStep
                onProceed={onProceed}
                wrapperType={account.wrapperType}
                onEdit={() => {
                  setActiveState('edit');
                }}
              />
            )}

            {open && activeState === 'edit' && (
              <RegularDepositEdit
                onClose={() => {
                  if (onlyShowEdit) {
                    closeDialog();
                  } else {
                    setActiveState('info');
                  }
                }}
                account={account}
              />
            )}
          </>
        )}
      </QueryState>
    </ResponsiveDialog>
  );
}

interface RegularDepositEditDialogProps extends ResponsiveDialogProps {
  index: number;
  open: boolean;
  closeDialog: () => void;
  account: AccountsQueryAccount | UserProfileQueryClientSummaryAccount;
}

export function RegularDepositEditDialog({
  index,
  open,
  closeDialog,
  account,
}: RegularDepositEditDialogProps) {
  const [editState, setEditState] = useState<number | null>(index);
  return (
    <ResponsiveDialog open={open} onClose={() => closeDialog()}>
      {open && editState !== null && (
        <RegularDepositEdit
          onClose={() => {
            setEditState(null);
            closeDialog();
          }}
          account={account}
        />
      )}
    </ResponsiveDialog>
  );
}
