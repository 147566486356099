import { TransferPrompt } from 'components/TransferPrompt/TransferPrompt';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { GaEventNames } from 'constants/gaConstants';
import { WrapperType } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { History } from 'history';
import { generateDashboardAccountAddCashPath } from 'paths';
import { useEffect } from 'react';
import { TransferPromptContainer } from './SecondAccount.styles';

interface SuccessStepProps {
  accountType: WrapperType;
  onClose: () => void;
  parentHistory: History;
}

const accountTypeFriendlyNameLookup: Partial<Record<WrapperType, string>> = {
  [WrapperType.Gia]: 'General Investment Account',
  [WrapperType.Isa]: 'Stocks & Shares ISA',
  [WrapperType.Sipp]: 'Pension',
};

export function SuccessStep({
  accountType,
  onClose,
  parentHistory,
}: SuccessStepProps) {
  const title = accountTypeFriendlyNameLookup[accountType] ?? accountType;

  useEffect(() => {
    parentHistory.push(`/dashboard/${accountType.toLowerCase()}`);
  }, [parentHistory, accountType]);

  const handleBrowseFunds = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'cta button',
      item_id: 'Browse funds',
    });
    onClose();
    parentHistory.push('/funds');
  };

  const handleAddCash = () => {
    trackGa({
      event: GaEventNames.viewItem,
      orderType: 'cash deposit',
      ecommerce: {
        items: [
          {
            item_id: 'cash',
            item_name: 'Cash deposit',
            affiliation: 'Tillit',
            currency: 'GBP',
            item_variant: accountType,
          },
        ],
      },
    });
    onClose();
    parentHistory.push(
      generateDashboardAccountAddCashPath({
        wrapperType: getPathSegmentForWrapperType(accountType),
      })
    );
  };

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>Your new {title} is ready!</StepTitle>
        <StepIntroduction $width={StepIntroductionWidth.normal}>
          <StepIntroductionTypography>
            We've sent you an email with your account details.
          </StepIntroductionTypography>
        </StepIntroduction>
      </StepContent>

      <StepActions $isHorizontal>
        <StepButton
          $minWidth="narrow"
          className="richBlue"
          onClick={handleBrowseFunds}
        >
          Browse funds
        </StepButton>
        <StepButton
          $minWidth="narrow"
          className="magenta"
          onClick={handleAddCash}
        >
          Add cash
        </StepButton>
      </StepActions>
      <TransferPromptContainer>
        <TransferPrompt
          source="Second account dialog"
          wrapperType={accountType}
          onClick={onClose}
        />
      </TransferPromptContainer>
    </StepContainer>
  );
}
