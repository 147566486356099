import { LinkCustomButton } from 'components/design-system/Button/CustomButtonV2';
import { TextNormal } from 'components/design-system/Text/Text';
import { GaEventNames } from 'constants/gaConstants';
import { useAuth } from 'context/AuthContext';
import {
  AccountType,
  OnboardingStatus,
  useOnboardingStatusQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { getNameForAccountType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { appSignInPath, openAccountPath, openAccountResumePath } from 'paths';
import { AiOutlineClose } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import {
  DismissButton,
  ResumeOnboardingNudgeContainer,
  ResumeOnboardingNudgeHead,
  ResumeOnboardingNudgeHeading,
} from './ResumeOnboardingNudge.styles';
import { useHasActiveAccount } from 'hooks/useAccountStatus';

export function ResumeOnboardingNudge() {
  const [dismissed, setDismissed] = useSessionStorage(
    'dismissed_resume_onboarding_nudge',
    false
  );
  const { signedIn } = useAuth();
  const userQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });
  const location = useLocation();
  const hideOnboardingNudge =
    location.pathname.includes(openAccountPath) ||
    location.pathname.includes(appSignInPath);

  const hasActiveAccount = useHasActiveAccount();
  const initialAccountType = userQuery.data?.userProfile?.initialAccountType;
  const onboardingStatusQuery = useOnboardingStatusQuery(undefined, {
    enabled: signedIn && hideOnboardingNudge && hasActiveAccount,
  });

  if (
    hideOnboardingNudge ||
    !initialAccountType ||
    initialAccountType === AccountType.NotSpecified ||
    initialAccountType === AccountType.Unknown
  ) {
    return null;
  }

  const status =
    onboardingStatusQuery.data?.userProfile?.onboardingDetails?.status;

  const initialAccountName = getNameForAccountType(initialAccountType);

  const isOnboarding =
    status === OnboardingStatus.FurtherInformationNeeded ||
    (status === OnboardingStatus.SupportNeeded &&
      onboardingStatusQuery.data?.userProfile
        ?.pendingDocumentUploadRequestCount === 1);

  const handleDismiss = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'cta button',
      item_id: 'finish setup dismissed - nudge',
    });
    setDismissed(true);
  };

  const handleResume = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'cta button',
      item_id: 'finish setup - nudge',
    });
    setDismissed(true);
  };

  return isOnboarding && !dismissed ? (
    <ResumeOnboardingNudgeContainer>
      <ResumeOnboardingNudgeHead>
        <ResumeOnboardingNudgeHeading $noMargin>
          Finish setting up your account
        </ResumeOnboardingNudgeHeading>
        <DismissButton aria-label="Close dialog" onClick={handleDismiss}>
          <AiOutlineClose />
        </DismissButton>
      </ResumeOnboardingNudgeHead>

      <TextNormal $isDarkBg>
        To start investing, browse more TILLIT Views or watch our exclusive Meet
        the Manager video interviews, please finish setting up your{' '}
        {initialAccountName}
      </TextNormal>
      <LinkCustomButton to={openAccountResumePath} onClick={handleResume}>
        Resume
      </LinkCustomButton>
    </ResumeOnboardingNudgeContainer>
  ) : null;
}
