import { useMediaQuery, useTheme } from '@material-ui/core';
import { SinglePill } from 'components/Pills';
import { CircularProgress } from 'components/Progress/CircularProgress/CircularProgress';
import { QueryState } from 'components/QueryState';
import { GaEventNames } from 'constants/gaConstants';
import * as format from 'formatting';
import { useAccountQuery, useAccountsQuery } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useMemo } from 'react';
import { RecommendedMinCashBalance } from 'strings/tooltips';
import { SupportedWrapperType } from 'types/utilityTypes';
import {
  DataContainerGraph,
  Info,
  Link,
  PillGroupContainer,
  PillLabel,
  PillValue,
} from './PillGroup.style';

export interface PillGroupProps {
  accountType: SupportedWrapperType;
}

export function PillGroup({ accountType }: PillGroupProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const accountsQuery = useAccountsQuery();
  const currentAccount = useMemo(
    () =>
      accountsQuery.data?.accounts?.find(
        (acc) => acc.wrapperType === accountType
      ),
    [accountType, accountsQuery.data?.accounts]
  );

  const accountQuery = useAccountQuery({ id: currentAccount?.id as string });

  const availableCash =
    accountQuery.data?.account!.valuationSummary!.uninvestedCash || 0;
  const pendingOrderValue = accountQuery.data?.account!.pendingBuyValue ?? 0;
  const recommendedCash =
    accountQuery.data?.account!.recommendedCashBalance || 0;

  const handleClickTracking = () => {
    trackGa({
      event: GaEventNames.popover,
      content_type: 'min cash popover',
    });
  };

  return (
    <QueryState {...accountQuery}>
      {() => (
        <>
          <PillGroupContainer>
            {pendingOrderValue > 0 && (
              <SinglePill>
                <PillValue>
                  {format.currencyFull(pendingOrderValue || '0')}
                </PillValue>
                <PillLabel>Pending Orders</PillLabel>
              </SinglePill>
            )}

            <SinglePill>
              <PillValue>{format.currencyFull(availableCash)}</PillValue>
              <PillLabel>Available cash</PillLabel>
            </SinglePill>

            <SinglePill>
              <DataContainerGraph>
                {matches && (
                  <CircularProgress
                    max={recommendedCash}
                    value={availableCash}
                  />
                )}
                {!matches && (
                  <PillValue>
                    {format.currencyFull(recommendedCash, false)}
                  </PillValue>
                )}
                <PillLabel>
                  Recommended Min cash balance
                  <Info
                    content={
                      <>
                        {RecommendedMinCashBalance}
                        <p>
                          You can find out more{' '}
                          <Link
                            href="https://tillitinvest.com/faqs#what-is-the-recommended-minimum-cash-balance-answer"
                            rel="noreferrer"
                            target="_blank"
                          >
                            here
                          </Link>
                          .
                        </p>
                      </>
                    }
                    onClick={handleClickTracking}
                  />
                </PillLabel>
              </DataContainerGraph>
            </SinglePill>
          </PillGroupContainer>
        </>
      )}
    </QueryState>
  );
}
