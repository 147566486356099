import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Loading } from 'components/Loading';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { StyledA } from 'components/design-system/Link';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import { TextAlign, TextNormal } from 'components/design-system/Text/Text';
import {
  AccountType,
  WrapperType,
  useApplyReferralCodeMutation,
  useReferralCodeQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { openPensionPath } from 'paths';
import { useForm } from 'react-hook-form';
import { AiOutlineClose } from 'react-icons/ai';
import { HiExternalLink } from 'react-icons/hi';
import {
  GoBackButton,
  StepContent,
  StepContentWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import {
  CheckboxContainer,
  CoBrandedLogo,
  ReferralActionsContainer,
  ReferralCoBrandingContainer,
  ReferralWelcomeContainer,
  TillitLogo,
} from './ConfirmReferralStep.styles';

interface ConfirmReferralStepFormValues {
  optIntoComplianceDataSharing?: boolean;
}

export type returnData = {
  employerName?: string;
  returnPath?: string;
};

export interface ConfirmReferralIntroCopyProps {
  mode: 'normal' | 'dialog';
  employerName?: string;
  hasPensionAccount?: boolean;
  initialAccountType?: AccountType;
}

function ConfirmReferralIntroCopy({
  hasPensionAccount,
  mode,
  employerName,
  initialAccountType,
}: ConfirmReferralIntroCopyProps) {
  if (initialAccountType !== AccountType.Sipp) {
    return null;
  } else if (hasPensionAccount || mode === 'dialog') {
    return (
      <>
        <TextNormal $textAlign={TextAlign.center}>
          <strong>{employerName}</strong> has offered to make pension
          contributions into your TILLIT Pension (a Self-Invested Personal
          Pension, or SIPP).
        </TextNormal>
      </>
    );
  } else {
    return (
      <>
        <TextNormal $textAlign={TextAlign.center}>
          <strong>{employerName}</strong> has offered to make pension
          contributions into a TILLIT Pension (a Self-Invested Personal Pension,
          or SIPP).
        </TextNormal>
      </>
    );
  }
}

export interface ConfirmReferralStepProps {
  mode?: 'normal' | 'dialog';
  referralCode: string;
  onProceed: ({ employerName, returnPath }: returnData) => void;
  onBack?: () => void;
}

export function ConfirmReferralStep({
  mode = 'normal',
  referralCode,
  onProceed,
  onBack,
}: ConfirmReferralStepProps) {
  const userProfileQuery = useUserProfileQuery();

  const hasPensionAccount = userProfileQuery.data?.clientSummary?.accounts?.some(
    (account) => {
      return account.wrapperType === WrapperType.Sipp;
    }
  );

  const {
    mutateAsync: applyReferralCodeMutate,
    isLoading,
    isError,
  } = useApplyReferralCodeMutation();

  const {
    data: referralCodeData,
    isLoading: referralDataLoading,
  } = useReferralCodeQuery(
    {
      code: referralCode,
    },
    {
      onSuccess: (data) => {
        const hasEmployer = !!data.referralCode?.employer;
        const contractNoteSharing =
          data?.referralCode?.employer?.complianceConfiguration
            ?.contractNoteSharing?.enabled ?? false;
        const initialAccountTypeSipp =
          data.referralCode?.initialAccountType === AccountType.Sipp;

        if (hasEmployer && (contractNoteSharing || initialAccountTypeSipp)) {
          return;
        }

        // We're only handling employer referrals for pensions & contract note sharing at the moment.
        // If the referral code is not for a pension or doesn't require contract note sharing, we'll just proceed.
        onProceed({});
      },
    }
  );

  const employerName =
    referralCodeData?.referralCode?.employer?.displayName ||
    referralCodeData?.referralCode?.employer?.companyName;
  const initialAccountType = referralCodeData?.referralCode?.initialAccountType;

  const complianceConfiguration =
    referralCodeData?.referralCode?.employer?.complianceConfiguration;
  const contractNoteSharing =
    complianceConfiguration?.contractNoteSharing?.enabled ?? false;

  const methods = useForm();
  const { handleSubmit } = methods;

  const onSubmit = async (data: ConfirmReferralStepFormValues) => {
    try {
      await applyReferralCodeMutate({
        input: {
          code: referralCode,
          optIntoComplianceDataSharing: data.optIntoComplianceDataSharing,
        },
      });

      if (initialAccountType === AccountType.Sipp && !hasPensionAccount) {
        window.location.href = openPensionPath;
      } else {
        onProceed({ employerName });
      }
    } catch {
      // Error handled by state
    }
  };

  return referralDataLoading ? (
    <Loading />
  ) : (
    <ReferralWelcomeContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StepContent width={StepContentWidth.extraWide}>
          {initialAccountType === AccountType.Sipp && hasPensionAccount && (
            <StepTitle>
              Allow {employerName} to contribute to your TILLIT Pension
            </StepTitle>
          )}
          {initialAccountType === AccountType.Sipp && !hasPensionAccount && (
            <StepTitle>Let's start your TILLIT Pension journey</StepTitle>
          )}
          {initialAccountType !== AccountType.Sipp && (
            <StepTitle>Link your account your {employerName}</StepTitle>
          )}

          {referralCodeData?.referralCode?.employer?.logoUrl && (
            <ReferralCoBrandingContainer>
              <TillitLogo />
              <AiOutlineClose />
              <CoBrandedLogo
                src={referralCodeData?.referralCode?.employer?.logoUrl}
                alt={employerName || ''}
              />
            </ReferralCoBrandingContainer>
          )}

          <ConfirmReferralIntroCopy
            hasPensionAccount={hasPensionAccount}
            mode={mode}
            employerName={employerName}
            initialAccountType={initialAccountType}
          />

          {contractNoteSharing && (
            <CheckboxContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    name="optIntoComplianceDataSharing"
                    inputRef={methods.register}
                  />
                }
                label={
                  complianceConfiguration?.policyLinkDescription ? (
                    <>
                      Forward contract notes to <strong>{employerName}</strong>,
                      as per their{' '}
                      {complianceConfiguration?.policyUrl ? (
                        <StyledA
                          href={complianceConfiguration?.policyUrl}
                          target="_blank"
                        >
                          {complianceConfiguration?.policyLinkDescription}{' '}
                          <HiExternalLink />
                        </StyledA>
                      ) : (
                        <>{complianceConfiguration?.policyLinkDescription}</>
                      )}
                    </>
                  ) : (
                    <>
                      Forward contract notes to <strong>{employerName}</strong>,
                      as per their trading policy
                    </>
                  )
                }
              />
            </CheckboxContainer>
          )}

          {initialAccountType === AccountType.Sipp && !hasPensionAccount && (
            <TextNormal $textAlign={TextAlign.center}>
              We'll spend the next few minutes helping you to decide if the
              TILLIT Pension is right for you and setting up your pension
              contributions.
            </TextNormal>
          )}

          <ServerError isVisible={isError} />

          <ReferralActionsContainer>
            <CustomButtonV2 disabled={isLoading} type="submit">
              {initialAccountType === AccountType.Sipp && !hasPensionAccount
                ? 'Open pension account'
                : 'Continue'}
            </CustomButtonV2>
            {onBack && <GoBackButton onClick={onBack} />}
          </ReferralActionsContainer>
        </StepContent>
      </form>
    </ReferralWelcomeContainer>
  );
}
