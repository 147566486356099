import {
  AccordionDetails,
  AccordionSummary,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { Loading } from 'components/Loading';
import {
  ButtonWrapper,
  CustomButtonV2,
} from 'components/design-system/Button/CustomButtonV2';
import { FontSize, Pill } from 'components/design-system/Pill/Pill';
import {
  GoBackButton,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { Text, TextAlign } from 'components/design-system/Text/Text';
import { ImportantBuyInformation } from 'components/feature/PortfolioBuilder/ImportantInformation/ImportantBuyInformation';
import { MinTradeUnitStatus } from 'components/feature/autoSaveInvest/regularInvest/_shared/MinTradeUnitStatus';
import { currencyFull, percent3dp } from 'formatting';
import {
  ChargesType,
  WrapperType,
  useAccountsQuery,
  useBuyOrderDetailsByAssetQuery,
  useInstrumentsByIsinsQuery,
  useRecurringTransactionsQuery,
} from 'generated/graphql';
import { getNameForWrapperType } from 'helpers/accountHelpers';
import { pensionTaxReliefHelpers } from 'helpers/pensionTaxReliefHelpers';
import { generateFundDetailsPath } from 'paths';
import { useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  InstrumentsByIsinsQueryInstrumentsInstrument,
  RecurringTransactionsQueryRecurringTransactionsOrders,
} from 'types/graphqlTypes';
import {
  AutoInvestOrderBuyInformation,
  AutoInvestOrderDetails,
  AutoInvestOrderDoc,
  AutoInvestOrderHeader,
  AutoInvestOrderInfo,
  AutoInvestOrderInstrumentName,
  AutoInvestOrderRow,
  AutoInvestOrderValues,
  AutoInvestOrdersContainer,
} from './styles/AutoInvest.styles';

type optionValues = 'keepCash' | 'autoInvest';

export interface proceedValues {
  option: optionValues;
  amount: number;
}

export interface AutoInvestStepInterface {
  wrapperType: WrapperType;
  accountId: string;
  amount: number;
  onBack: () => void;
  onProceed: (data: proceedValues) => void;
}

export function AutoInvestStep({
  wrapperType,
  accountId,
  amount,
  onBack,
  onProceed,
}: AutoInvestStepInterface) {
  const [submitting, setSubmitting] = useState(false);
  const recurringOrdersQuery = useRecurringTransactionsQuery(
    {
      accountId: accountId as string,
    },
    {
      enabled: !!accountId,
      onSuccess: (data) => {
        // if there is no recurring orders skip step
        data.recurringTransactions?.orders &&
          data.recurringTransactions?.orders?.length <= 0 &&
          onProceed({ option: 'keepCash', amount });
      },
    }
  );

  const recurringOrders =
    recurringOrdersQuery.data?.recurringTransactions?.orders;
  const isins = recurringOrders?.map(({ isin }) => isin);

  const instrumentsByIsinQuery = useInstrumentsByIsinsQuery(
    { isins: isins },
    {
      enabled: isins && isins.length > 0,
    }
  );

  const instruments = instrumentsByIsinQuery.data?.instrumentsByIsins?.nodes;

  const handleClick = (value: optionValues) => {
    setSubmitting(true);
    onProceed({
      option: value,
      amount,
    });
  };

  return (
    <>
      <StepTitle>How should we handle your contribution?</StepTitle>
      <DialogContent>
        <Text $textAlign={TextAlign.center}>
          You have a regular investment instruction set up against your TILLIT{' '}
          {getNameForWrapperType(wrapperType)} as detailed below.
        </Text>

        {instruments && instruments.length > 0 && (
          <AutoInvestOrdersContainer>
            {instruments?.map((instrument) => (
              <OrderRow
                key={instrument.isin}
                instrument={instrument}
                recurringOrders={recurringOrders!}
                amount={amount}
                accountId={accountId}
              />
            ))}
          </AutoInvestOrdersContainer>
        )}

        <Text $textAlign={TextAlign.center}>
          When your <strong>{currencyFull(amount)}</strong> arrives, TILLIT can
          invest it according to the proportions shown above,{' '}
          <strong>OR</strong> you can opt to add it as cash and invest it later.
        </Text>

        {wrapperType === WrapperType.Sipp && (
          <Text $textAlign={TextAlign.center} $noMargin>
            We'll handle the basic rate tax relief of{' '}
            {currencyFull(pensionTaxReliefHelpers(amount))} in the same way.
          </Text>
        )}
      </DialogContent>

      <DialogActions>
        {submitting && <Loading />}

        <ButtonWrapper>
          <CustomButtonV2
            disabled={submitting}
            $size="normal"
            $isWide={true}
            $color="primary"
            onClick={() => handleClick('autoInvest')}
          >
            Invest automatically
          </CustomButtonV2>
          <CustomButtonV2
            disabled={submitting}
            $size="normal"
            $isWide={true}
            $color="primary"
            onClick={() => handleClick('keepCash')}
          >
            Keep as cash
          </CustomButtonV2>
        </ButtonWrapper>
        <GoBackButton onClick={onBack}>Go back</GoBackButton>
      </DialogActions>
    </>
  );
}

interface OrderRowProps {
  accountId: string;
  amount: number;
  instrument: InstrumentsByIsinsQueryInstrumentsInstrument;
  recurringOrders: RecurringTransactionsQueryRecurringTransactionsOrders;
}

function OrderRow({
  accountId,
  amount,
  instrument,
  recurringOrders,
}: OrderRowProps) {
  const order = recurringOrders.find((order) => order.isin === instrument.isin);
  const [charges, setCharges] = useState<ChargesType | null | undefined>(null);

  const accountQuery = useAccountsQuery();
  const account = accountQuery.data?.accounts?.find(
    (account) => account.id === accountId
  );
  const positions = account?.positions || [];
  const position = positions.find(
    (position) => position.instrument?.isin === instrument.isin
  );

  useBuyOrderDetailsByAssetQuery(
    {
      assetId: `${instrument.assetId || ''}`,
      accountId: accountId || null,
    },
    {
      enabled: !!instrument.assetId,
      onSuccess: (data) => {
        const buyOrderDetails = data.buyOrderDetailsByAsset;
        setCharges(buyOrderDetails?.charges);
      },
    }
  );

  return (
    <AutoInvestOrderRow>
      <AccordionSummary expandIcon={<BiChevronDown />}>
        <AutoInvestOrderDetails>
          <AutoInvestOrderHeader>
            <AutoInvestOrderInstrumentName $noMargin>
              <Link
                to={{
                  pathname: generateFundDetailsPath({
                    id: instrument?.assetId!,
                    slug: instrument?.asset?.slug!,
                  }),
                  state: {
                    shouldGoBack: true,
                  },
                }}
                target="_blank"
              >
                {instrument.name} <FaExternalLinkAlt />
              </Link>
              {account &&
                instrument &&
                instrument.askPrice &&
                instrument.minimumTradeUnit && (
                  <MinTradeUnitStatus
                    variant="popover"
                    amount={amount}
                    askPrice={instrument.askPrice}
                    minimumTradeUnit={instrument.minimumTradeUnit}
                    fontSize={FontSize.small}
                    wrapperType={account.wrapperType}
                    orderType="one-off"
                  />
                )}
            </AutoInvestOrderInstrumentName>
            <AutoInvestOrderInfo>
              <Pill $fontSize={FontSize.small}>
                {instrument.asset?.assetClass?.name}
              </Pill>
              {instrument.kiidDocumentUrl && (
                <AutoInvestOrderDoc
                  to={{
                    pathname: instrument.kiidDocumentUrl,
                    state: {
                      shouldGoBack: true,
                    },
                  }}
                  target="_blank"
                >
                  KIID <FaExternalLinkAlt />
                </AutoInvestOrderDoc>
              )}

              {instrument.factsheetUrl && (
                <AutoInvestOrderDoc
                  to={{
                    pathname: instrument.factsheetUrl,
                    state: {
                      shouldGoBack: true,
                    },
                  }}
                  target="_blank"
                >
                  Factsheet <FaExternalLinkAlt />
                </AutoInvestOrderDoc>
              )}
            </AutoInvestOrderInfo>
          </AutoInvestOrderHeader>
          <AutoInvestOrderValues>
            {currencyFull(amount * order?.proportion!)} <span>/</span>{' '}
            {percent3dp(order?.proportion!)}
          </AutoInvestOrderValues>
        </AutoInvestOrderDetails>
      </AccordionSummary>
      <AccordionDetails>
        <AutoInvestOrderBuyInformation>
          <ImportantBuyInformation
            showTopBar={false}
            selectedInstrument={instrument}
            amountEntered={amount * order?.proportion!}
            existingPosition={position}
            charges={charges}
          />
        </AutoInvestOrderBuyInformation>
      </AccordionDetails>
    </AutoInvestOrderRow>
  );
}
