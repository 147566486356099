import { LocationKeyedSwitch } from 'components/LocationKeyedSwitch';
import { PageContainer } from 'components/PageContainer';
import { QueryState } from 'components/QueryState';
import { AnimatePresence } from 'framer-motion';
import { useUserProfileQuery, WrapperType } from 'generated/graphql';
import * as History from 'history';
import { MemoryRouter, Route, useHistory } from 'react-router-dom';
import { ConfirmationStep } from './ConfirmationStep';
import { ErrorStep } from './ErrorStep';
import { ISADeclarationStep } from './ISADeclarationStep';
import { NationalInsuranceNumberStep } from './NationalInsuranceNumberStep';
import { SuccessStep } from './SuccessStep';

interface SecondAccountProps {
  accountType: WrapperType;
  onClose: () => void;
  onCancel: () => void;
}

export function SecondAccount({
  accountType,
  onClose,
  onCancel,
}: SecondAccountProps) {
  const userProfileQuery = useUserProfileQuery({});

  const hasSubmittedNiNumber = !!userProfileQuery.data?.userProfile
    ?.nationalInsuranceNumberTokenised;
  const initialRouterEntries: History.LocationDescriptor[] = [
    {
      pathname:
        accountType === WrapperType.Gia
          ? '/confirmation'
          : hasSubmittedNiNumber
          ? '/isaDeclaration'
          : '/niNumber',
    },
  ];

  const history = useHistory();

  return (
    <PageContainer>
      <QueryState {...userProfileQuery}>
        {() => (
          <MemoryRouter initialEntries={initialRouterEntries}>
            <AnimatePresence>
              <LocationKeyedSwitch>
                <Route path="/niNumber">
                  <NationalInsuranceNumberStep
                    onGoBack={() => {
                      onClose();
                    }}
                    userProfile={userProfileQuery.data!.userProfile!}
                  />
                </Route>
                <Route path="/isaDeclaration">
                  <ISADeclarationStep onBack={onClose} />
                </Route>
                <Route path="/confirmation">
                  <ConfirmationStep
                    accountType={accountType}
                    onClose={onClose}
                    onCancel={onCancel}
                  />
                </Route>
                <Route path="/error">
                  <ErrorStep accountType={accountType} onClose={onClose} />
                </Route>
                <Route path="/success">
                  <SuccessStep
                    accountType={accountType}
                    onClose={onClose}
                    parentHistory={history}
                  />
                </Route>
              </LocationKeyedSwitch>
            </AnimatePresence>
          </MemoryRouter>
        )}
      </QueryState>
    </PageContainer>
  );
}
