import { yupResolver } from '@hookform/resolvers/yup';
import { QueryState } from 'components/QueryState';
import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import {
  NullableRadioFormState,
  YesNoRadios,
} from 'components/design-system/FormElements/YesNoRadios';
import { StyledLink } from 'components/design-system/Link';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import {
  StepActions,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { FontWeight, TextNormal } from 'components/design-system/Text/Text';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import { currencyFull } from 'formatting';
import {
  IllustrationQuery,
  useIllustrationQuery,
  useReferralCodeQuery,
  useUpdateIllustrationMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useRef, useState } from 'react';
import {
  FieldError,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useIntercom } from 'react-use-intercom';
import { useEventListener } from 'usehooks-ts';
import * as Yup from 'yup';
import {
  ReferralConfirmationDialog,
  useDialog,
} from '../../dialogs/ReferralConfirmationDialog/ReferralConfirmationDialog';
import { FormActions } from '../_shared/FormActions';
import { MoreInfoWrapper } from '../_shared/steps.styles';
import {
  DetailsFieldLabelWrapper,
  RadiosWrapper,
  ReferralInputWrapper,
  RootErrorMessage,
  StyledTextField,
  TaxReclaimLabelContent,
} from './FundingChoicesStep.styles';

function useInvalidateMutatedQueries() {
  const queryClient = useQueryClient();

  return async () => {
    await queryClient.invalidateQueries(useIllustrationQuery.getKey());
  };
}

function checkAnyHasValue(data: IllustrationQuery['illustration']) {
  if (
    data?.initialPayment?.amount ||
    data?.transferFromAnotherProvider?.amount ||
    data?.monthlyEmployeeContribution?.amount ||
    data?.monthlyEmployerContribution?.amount
  )
    return true;
  return false;
}

function getBooleanFormValueString(
  field: number | undefined,
  anyFieldHasData: boolean
): NullableRadioFormState {
  return field ? 'true' : anyFieldHasData ? 'false' : undefined;
}

const booleanSchema = Yup.boolean()
  .typeError('Please answer yes or no')
  .required();

const amountSchema = Yup.number()
  .typeError('Please enter a valid amount between £0 and £10,000,000')
  .min(0, 'The amount must be between £0 and £10,000,000')
  .max(10000000, 'The amount must be less than or equal to £10,000,000')
  .nullable()
  .transform((_, value) => (value === '' ? null : Number(value)));

const amountSchemaRequired = Yup.number()
  .typeError('Please enter a valid amount between £0 and £10,000,000')
  .min(0, 'The amount must be between £0 and £10,000,000')
  .max(10000000, 'The amount must be less than or equal to £10,000,000')
  .required()
  .transform((_, value) => (value === '' ? null : Number(value)));

const monthlyAmountSchema = Yup.number()
  .typeError('Please enter a valid amount between £0 and £5,000')
  .min(0, 'The amount must be between £0 and £5,000')
  .max(5000, 'The amount must be less than or equal to £5,000')
  .nullable()
  .transform((_, value) => (value === '' ? null : Number(value)));

const monthlyAmountSchemaRequired = Yup.number()
  .typeError('Please enter a valid amount between £0 and £5,000')
  .min(0, 'The amount must be between £0 and £5,000')
  .max(5000, 'The amount must be less than or equal to £5,000')
  .required()
  .transform((_, value) => (value === '' ? null : Number(value)));

const fundingChoicesFormSchema = Yup.object({
  byLumpSum: booleanSchema,
  initialPayment: amountSchema.when(['byLumpSum'], {
    is: (byLumpSum: boolean) => byLumpSum === true,
    then: amountSchemaRequired,
  }),
  byMonthlyEmployeeContribution: booleanSchema,
  monthlyEmployeeContribution: monthlyAmountSchema.when(
    ['byMonthlyEmployeeContribution'],
    {
      is: (byMonthlyEmployeeContribution: boolean) =>
        byMonthlyEmployeeContribution === true,
      then: monthlyAmountSchemaRequired,
    }
  ),
  byMonthlyEmployerContribution: booleanSchema,
  monthlyEmployerContribution: monthlyAmountSchema.when(
    ['byMonthlyEmployerContribution'],
    {
      is: (byMonthlyEmployerContribution: boolean) =>
        byMonthlyEmployerContribution === true,
      then: monthlyAmountSchemaRequired,
    }
  ),
  byTransferFromAnotherProvider: booleanSchema,
  transferFromAnotherProvider: amountSchema.when(
    ['byTransferFromAnotherProvider'],
    {
      is: (byTransferFromAnotherProvider: boolean) =>
        byTransferFromAnotherProvider === true,
      then: amountSchemaRequired,
    }
  ),
  myEmployerReferralCode: Yup.string().max(
    12,
    'Your referral code cannot be longer than 12 characters'
  ),
}).test({
  name: 'root',
  message: 'You must specify at least one funding method above.',
  test: (value) =>
    !!(
      value?.initialPayment ||
      value?.transferFromAnotherProvider ||
      value?.monthlyEmployeeContribution ||
      value?.monthlyEmployerContribution
    ),
});

type FundingChoicesStepFormValues = Yup.InferType<
  typeof fundingChoicesFormSchema
>;

interface FundingChoicesStepFormProps extends FundingChoicesStepProps {
  data: IllustrationQuery['illustration'];
}

function FundingChoicesStepForm({
  source,
  onProceed,
  onGoBack,
  data,
}: FundingChoicesStepFormProps) {
  const documentRef = useRef<Document>(document);
  const dialogProps = useDialog();
  const { showNewMessages } = useIntercom();
  const userProfileQuery = useUserProfileQuery();
  const hasEmployments =
    userProfileQuery.data?.userProfile?.employments?.length || null;

  const hasEmployer =
    (userProfileQuery.data?.userProfile?.employments || []).length > 0;

  const [referralCode, setReferralCode] = useState<string | null>(null);
  const [linkedEmployer, setLinkedEmployer] = useState<string | undefined>(
    undefined
  );

  const {
    isLoading: referralDataLoading,
    data: referralData,
  } = useReferralCodeQuery(
    {
      code: referralCode!,
    },
    {
      enabled: !!referralCode,
      onSuccess: (data) => {
        if (!data.referralCode) {
          setReferralCode(null);
          setError('myEmployerReferralCode', {
            type: 'manual',
            message:
              'This referral code is not valid, please try another code or contact our support team.',
          });
        } else {
          clearErrors('myEmployerReferralCode');
          dialogProps.openDialog();
        }
      },
    }
  );

  const {
    mutateAsync: updateIllustration,
    isError,
    reset,
  } = useUpdateIllustrationMutation();

  const invalidateQueries = useInvalidateMutatedQueries();

  const anyHasValue = checkAnyHasValue(data);

  const defaultValues = {
    byLumpSum: getBooleanFormValueString(
      data?.initialPayment?.amount,
      anyHasValue
    ),
    initialPayment: data?.initialPayment?.amount ?? null,
    byTransferFromAnotherProvider: getBooleanFormValueString(
      data?.transferFromAnotherProvider?.amount,
      anyHasValue
    ),
    transferFromAnotherProvider:
      data?.transferFromAnotherProvider?.amount ?? null,
    byMonthlyEmployeeContribution: getBooleanFormValueString(
      data?.monthlyEmployeeContribution?.amount,
      anyHasValue
    ),
    monthlyEmployeeContribution:
      data?.monthlyEmployeeContribution?.amount ?? null,
    byMonthlyEmployerContribution:
      getBooleanFormValueString(
        data?.monthlyEmployerContribution?.amount,
        anyHasValue
      ) || ((hasEmployer ? 'true' : undefined) as 'true' | 'false' | undefined),
    monthlyEmployerContribution:
      data?.monthlyEmployerContribution?.amount ?? null,
    myEmployerReferralCode: undefined,
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(fundingChoicesFormSchema),
  });

  const {
    watch,
    handleSubmit,
    formState: { isDirty, isSubmitting, errors },
    register,
    setError,
    clearErrors,
    getValues,
  } = methods;

  const rootError: FieldError = (errors as any).root;
  const referralError = errors.myEmployerReferralCode ? true : false;

  const onSubmit: SubmitHandler<FundingChoicesStepFormValues> = async (
    data
  ) => {
    reset();
    try {
      if (isDirty) {
        const toPayload = (value: number | null | undefined) =>
          value ? { amount: value } : undefined;

        await updateIllustration({
          input: {
            initialPayment: toPayload(data.initialPayment),
            monthlyEmployeeContribution: toPayload(
              data.monthlyEmployeeContribution
            ),
            monthlyEmployerContribution: toPayload(
              data.monthlyEmployerContribution
            ),
            transferFromOtherProviders: toPayload(
              data.transferFromAnotherProvider
            ),
          },
        });
        await invalidateQueries();
      }

      if (source === 'openAccountStep') {
        trackGa({
          event: GaEventNames.onboarding,
          onboardingStep: OnboardingStepNames.fundingChoices,
        });
      }
      onProceed(linkedEmployer ? true : false);
    } catch {
      // error handled by state
    }
  };

  const byMonthlyEmployeeContribution = watch('byMonthlyEmployeeContribution');
  const byMonthlyEmployerContribution = watch('byMonthlyEmployerContribution');
  const byLumpSum = watch('byLumpSum');
  const byTransferFromAnotherProvider = watch('byTransferFromAnotherProvider');

  const monthlyEmployeeContributionAmount = watch(
    'monthlyEmployeeContribution'
  );
  const initialPaymentAmount = watch('initialPayment');

  const handleSupport = () => {
    showNewMessages('I need help linking my TILLIT account to my employer.');
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: `Pension onboarding  - cannot link employer`,
    });
  };

  const handleReferral = (code: string) => {
    const pattern = /^[a-zA-Z0-9-]+$/;

    if (!pattern.test(code)) {
      setError('myEmployerReferralCode', {
        type: 'manual',
        message: 'Your referral code can only contain alphanumeric characters.',
      });
      return;
    }

    if (!referralError && code === referralCode && !dialogProps.open) {
      dialogProps.openDialog();
    } else {
      clearErrors('myEmployerReferralCode');
      setReferralCode(code);
    }
  };

  const onVisibilityChange = () => {
    if (referralCode && !dialogProps.open) setReferralCode(null);
    return;
  };

  useEventListener('visibilitychange', onVisibilityChange, documentRef);
  useEventListener('blur', onVisibilityChange);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <StepContainer>
            <StepTitle>How will you be funding your pension?</StepTitle>
            <StepContent width={StepContentWidth.extraWide}>
              <StepText>
                <StepIntroductionTypography>
                  To better illustrate how your pension value might change over
                  time so that you can determine if this is the right choice for
                  you, we need to learn a bit more about how you want to fund
                  your TILLIT Pension.
                </StepIntroductionTypography>
                <StepIntroductionTypography>
                  Fill in the details as best you can.
                </StepIntroductionTypography>
              </StepText>
            </StepContent>
            <ServerError isVisible={isError} />

            <StepContent width={StepContentWidth.extraWide}>
              <RadiosWrapper>
                <YesNoRadios
                  name="byMonthlyEmployerContribution"
                  register={register}
                  error={errors.byMonthlyEmployerContribution}
                  defaultValue={defaultValues.byMonthlyEmployerContribution}
                  label={
                    <TextNormal $noMargin>
                      By your employer contributing on your behalf?
                    </TextNormal>
                  }
                />
                {byMonthlyEmployerContribution === 'true' && (
                  <MoreInfoWrapper>
                    <DetailsFieldLabelWrapper>
                      <StyledTextField
                        name="monthlyEmployerContribution"
                        id="employerContribution"
                        label=""
                        hideLabel={true}
                        startAdornment="£"
                      />
                      <TextNormal $noMargin $fontWeight={FontWeight.medium}>
                        per month
                      </TextNormal>
                    </DetailsFieldLabelWrapper>
                    <TextNormal $noMargin={hasEmployments && !linkedEmployer}>
                      You should include all contributions via your employment
                      here, both yours and your employer's. We assume that your
                      employer is paying in gross and that there's no tax to be
                      reclaimed.
                    </TextNormal>

                    {linkedEmployer && (
                      <TextNormal $noMargin>
                        {linkedEmployer} will start to make contributions to
                        your TILLIT Pension once you finish opening your
                        account.
                      </TextNormal>
                    )}

                    {!hasEmployments && !linkedEmployer && (
                      <>
                        <TextNormal $noMargin>
                          Received a link from your employer? In order to link
                          this account to your employer type in the referral
                          code found on your invite email or{' '}
                          <StyledLink onClick={handleSupport}>
                            get in touch
                          </StyledLink>{' '}
                          with our support team.
                        </TextNormal>

                        <ReferralInputWrapper>
                          <StyledTextField
                            name="myEmployerReferralCode"
                            id="employerReferralCode"
                            label="Employer referral code"
                            register={register}
                            $fullWidth
                          />
                          <CustomButtonV2
                            $size="small"
                            $noHover
                            $color="secondary"
                            onClick={() => {
                              const code = getValues();
                              handleReferral(
                                code?.myEmployerReferralCode || ''
                              );
                            }}
                          >
                            Link employer
                          </CustomButtonV2>
                        </ReferralInputWrapper>
                      </>
                    )}
                  </MoreInfoWrapper>
                )}
                <YesNoRadios
                  name="byMonthlyEmployeeContribution"
                  register={register}
                  error={errors.byMonthlyEmployeeContribution}
                  defaultValue={defaultValues.byMonthlyEmployeeContribution}
                  label={
                    <TextNormal $noMargin>
                      By topping up from your bank account monthly?
                    </TextNormal>
                  }
                />
                {byMonthlyEmployeeContribution === 'true' && (
                  <MoreInfoWrapper>
                    <DetailsFieldLabelWrapper>
                      <StyledTextField
                        name="monthlyEmployeeContribution"
                        id="employeeContribution"
                        data-testid="employeeContribution-input"
                        label=""
                        register={register}
                        hideLabel={true}
                        startAdornment="£"
                        $noMargin
                      />
                      <TextNormal $noMargin $fontWeight={FontWeight.medium}>
                        per month
                        {(monthlyEmployeeContributionAmount ?? 0) > 0 && (
                          <TaxReclaimLabelContent>{` (+${currencyFull(
                            0.25 * monthlyEmployeeContributionAmount!
                          )} in basic rate tax relief)`}</TaxReclaimLabelContent>
                        )}
                      </TextNormal>
                    </DetailsFieldLabelWrapper>
                  </MoreInfoWrapper>
                )}
                <YesNoRadios
                  name="byLumpSum"
                  register={register}
                  error={errors.byLumpSum}
                  defaultValue={defaultValues.byLumpSum}
                  label={
                    <TextNormal $noMargin>
                      By adding a cash lump sum?
                    </TextNormal>
                  }
                />
                {byLumpSum === 'true' && (
                  <MoreInfoWrapper>
                    <DetailsFieldLabelWrapper>
                      <StyledTextField
                        name="initialPayment"
                        id="lumpSum"
                        register={register}
                        label=""
                        hideLabel={true}
                        startAdornment="£"
                        $noMargin
                      />
                      <TextNormal $noMargin>
                        {(initialPaymentAmount ?? 0) > 0
                          ? ` (+${currencyFull(
                              0.25 * initialPaymentAmount!
                            )} in basic rate tax relief)`
                          : ''}
                      </TextNormal>
                    </DetailsFieldLabelWrapper>
                  </MoreInfoWrapper>
                )}
                <YesNoRadios
                  name="byTransferFromAnotherProvider"
                  register={register}
                  error={errors.byTransferFromAnotherProvider}
                  defaultValue={defaultValues.byTransferFromAnotherProvider}
                  label={
                    <TextNormal $noMargin>
                      By transferring or consolidating other pensions into this
                      one?
                    </TextNormal>
                  }
                />
                {byTransferFromAnotherProvider === 'true' && (
                  <MoreInfoWrapper>
                    <label htmlFor="transferField">
                      Approximate value to transfer from other pension pots
                    </label>
                    <DetailsFieldLabelWrapper>
                      <StyledTextField
                        name="transferFromAnotherProvider"
                        id="transferField"
                        label=""
                        hideLabel={true}
                        startAdornment="£"
                      />
                    </DetailsFieldLabelWrapper>
                    <TextNormal $noMargin>
                      Not all pension schemes can be transferred in (for example
                      NHS or Civil Service pensions), and if you want to
                      transfer from a Defined Benefit (or 'Final Salary')
                      pension scheme we may need you to seek financial advice
                      first.
                    </TextNormal>
                  </MoreInfoWrapper>
                )}
              </RadiosWrapper>
            </StepContent>
            <StepActions>
              {rootError && (
                <RootErrorMessage error={true}>
                  {rootError.message}
                </RootErrorMessage>
              )}
              <FormActions
                onGoBack={onGoBack}
                disabled={isSubmitting || referralDataLoading}
              />
            </StepActions>
          </StepContainer>
        </FormProvider>
      </form>

      {dialogProps.open && referralData && referralCode && (
        <ReferralConfirmationDialog
          referralCode={referralCode}
          onProceed={(employerName) => setLinkedEmployer(employerName)}
          {...dialogProps}
        />
      )}
    </>
  );
}

interface FundingChoicesStepProps {
  source?: 'openAccountStep';
  onProceed: (employeeAdded?: boolean) => void;
  onGoBack: () => void;
}

export function FundingChoicesStep(props: FundingChoicesStepProps) {
  const illustrationQuery = useIllustrationQuery();

  return (
    <QueryState {...illustrationQuery}>
      {(queryResult) => (
        <FundingChoicesStepForm
          {...props}
          data={queryResult.data?.illustration}
        />
      )}
    </QueryState>
  );
}
