import {
  MinTradeUnitStatusEnum,
  deriveMinTradeUnitStatus,
} from 'components/Dialogs/Trading/helpers';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import {
  FontSize,
  Text,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { currencyFull } from 'formatting';
import { WrapperType } from 'generated/graphql';
import { useToggle } from 'hooks/useFeatureToggle';
import 'numeral/locales/en-gb';
import React from 'react';
import styled from 'styled-components';

const MinTradeUnitStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${Text} {
    color: ${colors.danger};
  }
`;

interface MinTradeUnitStatusDetailsProps {
  summary: React.ReactNode;
  details: React.ReactNode;
  fontSize: FontSize;
}

export const MinTradeUnitStatusDetailsPopover = ({
  summary,
  details,
  fontSize = FontSize.normal,
}: MinTradeUnitStatusDetailsProps) => {
  return (
    <Text $noMargin $fontSize={fontSize}>
      {summary}
      <InfoPopoverV2
        color="secondary"
        $width="wide"
        placement={'top'}
        size="small"
        content={<TextSmall>{details}</TextSmall>}
      />
    </Text>
  );
};

export const MinTradeUnitStatusDetailsDisplay = ({
  summary,
  details,
}: MinTradeUnitStatusDetailsProps) => {
  return (
    <>
      <TextNormal $noMargin>{summary}</TextNormal>
      <TextSmall>{details}</TextSmall>
    </>
  );
};

interface MinTradeUnitStatusProps {
  wrapperType: WrapperType;
  minimumTradeUnit: number;
  askPrice: number;
  amount: number;
  variant?: 'popover' | 'display';
  fontSize?: FontSize;
  orderType: 'one-off' | 'recurring';
}

export const MinTradeUnitStatus = ({
  wrapperType,
  amount,
  askPrice,
  minimumTradeUnit,
  variant = 'display',
  fontSize = FontSize.normal,
  orderType,
}: MinTradeUnitStatusProps) => {
  const [showMinTradeUnitStatus] = useToggle(
    'global-show-min-trade-unit-status'
  );

  const minTradeUnitStatus = deriveMinTradeUnitStatus({
    tradeAmount: amount,
    askPrice,
    minTradeableUnit: minimumTradeUnit,
  });

  const taxReliefAmount = amount * 0.25;
  const taxReliefMinTradeUnitStatus =
    wrapperType === WrapperType.Sipp
      ? deriveMinTradeUnitStatus({
          tradeAmount: taxReliefAmount,
          askPrice,
          minTradeableUnit: minimumTradeUnit,
        })
      : null;

  if (!showMinTradeUnitStatus || amount === 0) {
    return null;
  }

  const MinTradeUnitStatusDetails =
    variant === 'display'
      ? MinTradeUnitStatusDetailsDisplay
      : MinTradeUnitStatusDetailsPopover;

  return (
    <MinTradeUnitStatusWrapper>
      {minTradeUnitStatus?.status ===
        MinTradeUnitStatusEnum.lessThanMinimumTradeUnit && (
        <MinTradeUnitStatusDetails
          fontSize={fontSize}
          summary={
            <>
              <strong>Please note</strong> - this cash will be left uninvested.
            </>
          }
          details={
            <>
              The current minimum tradeable amount for this fund is{' '}
              {currencyFull(minTradeUnitStatus.minTradeableAmount)}. This is
              higher than the amount you have entered - and so we will not be
              able to invest this cash in this fund. The minimum tradeable
              amount will fluctuate over time as prices change.
            </>
          }
        />
      )}
      {minTradeUnitStatus?.status ===
        MinTradeUnitStatusEnum.closeToMinimumTradeUnit && (
        <MinTradeUnitStatusDetails
          fontSize={fontSize}
          summary={
            <>
              <strong>Please note</strong> - this cash could be left uninvested.
            </>
          }
          details={
            <>
              The current minimum tradeable amount for this fund is{' '}
              {currencyFull(minTradeUnitStatus.minTradeableAmount)}. The minimum
              tradeable amount will fluctuate over time as prices change. If the
              minimum tradeable amount increases above the amount you have
              entered then this cash could be left uninvested.
            </>
          }
        />
      )}
      {minTradeUnitStatus?.status ===
        MinTradeUnitStatusEnum.highProportionUntradeable && (
        <MinTradeUnitStatusDetails
          fontSize={fontSize}
          summary={
            <>
              <strong>Please note</strong> - a high proportion of this cash will
              be left uninvested.
            </>
          }
          details={
            <>
              The current minimum tradeable amount for this fund is{' '}
              {currencyFull(minTradeUnitStatus.minTradeableAmount)}. This means
              if you allocate {currencyFull(amount)} to this fund then we will
              only be able to instruct a trade for{' '}
              {currencyFull(amount - minTradeUnitStatus.estNotInvested)}, and 
              <strong>
                {currencyFull(minTradeUnitStatus.estNotInvested)} would be left
                uninvested{orderType === 'recurring' && ' each month'}
              </strong>
              . The minimum tradeable amount will fluctuate over time as prices
              change.
            </>
          }
        />
      )}
      {minTradeUnitStatus?.status ===
        MinTradeUnitStatusEnum.closeToHighProportionUntradeable && (
        <MinTradeUnitStatusDetails
          fontSize={fontSize}
          summary={
            <>
              <strong>Please note</strong> - a high proportion of this cash
              could be left uninvested.
            </>
          }
          details={
            <>
              The current minimum tradeable amount for this fund is{' '}
              {currencyFull(minTradeUnitStatus.minTradeableAmount)}. The minimum
              tradeable amount will fluctuate over time as prices change. If the
              minimum tradeable amount increases then a high proportion of this
              cash could be left uninvested.
            </>
          }
        />
      )}

      {minTradeUnitStatus === null &&
        taxReliefMinTradeUnitStatus?.status ===
          MinTradeUnitStatusEnum.lessThanMinimumTradeUnit && (
          <MinTradeUnitStatusDetails
            fontSize={fontSize}
            summary={
              <>
                <strong>Please note</strong> - the basic rate tax relief will be
                left uninvested.
              </>
            }
            details={
              <>
                The current minimum tradeable amount for this fund is{' '}
                {currencyFull(taxReliefMinTradeUnitStatus.minTradeableAmount)}.
                This is higher than the basic rate tax relief we will claim back
                for you - and so we will not be able to invest this cash in this
                fund. The minimum tradeable amount will fluctuate over time as
                prices change.
              </>
            }
          />
        )}
      {minTradeUnitStatus === null &&
        taxReliefMinTradeUnitStatus?.status ===
          MinTradeUnitStatusEnum.closeToMinimumTradeUnit && (
          <MinTradeUnitStatusDetails
            fontSize={fontSize}
            summary={
              <>
                <strong>Please note</strong> - the basic rate tax relief could
                be left uninvested.
              </>
            }
            details={
              <>
                The current minimum tradeable amount for this fund is{' '}
                {currencyFull(taxReliefMinTradeUnitStatus.minTradeableAmount)}.
                The minimum tradeable amount will fluctuate over time as prices
                change. If the minimum tradeable amount increases above the
                amount of basic rate tax relief cash we claim back for you the
                tax relief could be left uninvested.
              </>
            }
          />
        )}
      {minTradeUnitStatus === null &&
        taxReliefMinTradeUnitStatus?.status ===
          MinTradeUnitStatusEnum.highProportionUntradeable && (
          <MinTradeUnitStatusDetails
            fontSize={fontSize}
            summary={
              <>
                <strong>Please note</strong> - a high proportion of the basic
                rate tax relief cash will be left uninvested.
              </>
            }
            details={
              <>
                The current minimum tradeable amount for this fund is{' '}
                {currencyFull(taxReliefMinTradeUnitStatus.minTradeableAmount)}.
                We will claim basic rate tax relief for you and allocated
                {currencyFull(taxReliefAmount)} of this to fund. We will only be
                able to instruct a trade for{' '}
                {currencyFull(
                  taxReliefAmount - taxReliefMinTradeUnitStatus.estNotInvested
                )}
                , and{' '}
                <strong>
                  {currencyFull(taxReliefMinTradeUnitStatus.estNotInvested)}{' '}
                  would be left uninvested each month
                </strong>
                . The minimum tradeable amount will fluctuate over time as
                prices change.
              </>
            }
          />
        )}
      {minTradeUnitStatus === null &&
        taxReliefMinTradeUnitStatus?.status ===
          MinTradeUnitStatusEnum.closeToHighProportionUntradeable && (
          <MinTradeUnitStatusDetails
            fontSize={fontSize}
            summary={
              <>
                <strong>Please note</strong> - a high proportion of the basic
                rate tax relief could be left uninvested.
              </>
            }
            details={
              <>
                The current minimum tradeable amount for this fund is{' '}
                {currencyFull(taxReliefMinTradeUnitStatus.minTradeableAmount)}.
                The minimum tradeable amount will fluctuate over time as prices
                change. If the minimum tradeable amount increases then a high
                proportion of the tax relief could be left uninvested.
              </>
            }
          />
        )}
    </MinTradeUnitStatusWrapper>
  );
};
