import { Link } from '@material-ui/core';
import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

interface StyledLinkProps {
  $colorStyle?: 'light' | 'dark';
  $icon?: boolean;
}

export const StyledLink = styled(Link)<StyledLinkProps>`
  position: relative;
  color: ${(p) =>
    p.$colorStyle === 'dark' ? colors.magenta : colors.white} !important;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: ${(p) =>
      p.$colorStyle === 'dark'
        ? transparentize(0.75, colors.magenta)
        : transparentize(0.75, colors.white)};
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    transform: rotateY(90deg);
    background-color: ${(p) =>
      p.$colorStyle === 'dark' ? colors.magenta : colors.white};
    transition: transform 0.4s ease-in-out;
  }

  &:hover {
    text-decoration: none;
    &:after {
      transform: rotateY(0deg);
    }
  }

  ${({ $icon }) =>
    $icon &&
    css`
      svg {
        vertical-align: middle;
      }
    `}
`;

/**
 * Used to style a tags within content where we can't use <StyledLink />
 * e.g. tillit view / rationale content which arrives as an html string
 */
export const styledLinkStyleOnly = css`
  background: none;
  border: none;
  padding: 0;
  color: ${colors.magenta} !important;

  &,
  &:visited,
  &:hover,
  &:focus {
    position: relative;
    color: ${colors.magenta};
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: ${transparentize(0.75, colors.magenta)};
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    transform: rotateY(90deg);
    background-color: ${colors.magenta};
    transition: transform 0.4s ease-in-out;
  }

  &:hover {
    text-decoration: none;
    &:after {
      transform: rotateY(0deg);
    }
  }
`;

StyledLink.defaultProps = {
  $colorStyle: 'dark',
};

export const StyledA = styled.a`
  ${styledLinkStyleOnly};
`;
